import React from "react"
import { css } from "@emotion/core"
import linkResolver from "../utils/linkResolver"
import ContentDecoration from "./UI/ContentDecoration"
import List from "./UI/List"
import RichText from "./UI/RichText"
import { Link } from "gatsby"
import DateLabel from "components/UI/DateLabel"
import t from "theme"

const InterviewFull = ({ interviewData }) => {
    const company = interviewData.companies ? interviewData.companies[0] : null

    const linkUrl = linkResolver({ slug: interviewData.slug, type: "interview" })

    const body = interviewData.text.json
    const interviewQAs = interviewData.answer

    const headline = interviewData.headline
    const sortTimestamp = interviewData.sortTimestamp
    console.log(interviewQAs)

    return (
        <div>
            <div
                css={css`
                    margin-bottom: ${t.space[6]};
                `}>
                <ContentDecoration type="INTERVIEW" company={company} />

                <h1
                    css={css`
                        padding-top: ${t.space[2]};
                        padding-bottom: ${t.space[2]};
                    `}>
                    <Link to={linkUrl}>{headline}</Link>
                </h1>

                <DateLabel date={sortTimestamp} />
            </div>
            {/*
            <List
                items={interviewQAList.map((interviewQA, index) => (
                    <>
                        <strong>
                            <RichText
                                css={css`
                                    margin-bottom: ${t.space[3]};
                                `}
                                body={interviewQA.question.question.json}
                                linkUrl={linkUrl}
                            />
                        </strong>
                        <RichText body={interviewQA.answer.json} linkUrl={linkUrl} />
                    </>
                ))}
                space={t.space[7]}
                nodivider
            />
                */}
            <RichText body={body} linkUrl={linkUrl} interviewQAs={interviewQAs} />
        </div>
    )
}

export default InterviewFull

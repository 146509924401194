import React, { useState } from "react"

import styled from "@emotion/styled"
import { css } from "@emotion/core"
import VisibilitySensor from "react-visibility-sensor"
import { mixin } from "theme"

function FadeBox({ height, children, ...props }) {
    const [isHidden, setHidden] = useState(true)

    return (
        <div
            css={css`
                position: relative;
            `}
            {...props}>
            <VisibilitySensor onChange={setHidden}>
                <div
                    css={css`
                        position: absolute;
                        margin-top: ${height};
                    `}>
                    &nbsp;
                </div>
            </VisibilitySensor>

            <Revealable show={!isHidden}>
                <div>{children}</div>
            </Revealable>
        </div>
    )
}

export default FadeBox

const Revealable = styled("div")`
    ${mixin.desktopstyle} {
        position: fixed;
        margin-top: 8rem;
        top: 0;
        width: 156px;
        opacity: ${({ show }) => (show ? "100" : "0")};
        transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -webkit-transition: opacity 0.3s ease-in-out;
    }
`

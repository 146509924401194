import React from "react"
import styled from "@emotion/styled"
import { css, Global } from "@emotion/core"
import Footer from "components/Common/Footer"
import SignUpBox from "components/Auth/SignUpBox"
import FadeBox from "components/UI/FadeBox"
import t, { mixin, globalStyle } from "theme"
import { useMediaQuery } from "react-responsive"
import "../../content/dricons.css"

/*
three differences:
max-content vs auto
use of MediaQuery
use of ...props
use of fadebox
consider avoiding duplicate grid area names
*/

const CenteredLayout = ({ header, sidebar, children, ...props }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${t.breakpoint.large})`,
    })
    return (
        <GridLayout {...props}>
            <Global styles={globalStyle} />
            <div
                css={css`
                    grid-area: head;
                `}>
                {header}
            </div>

            <div
                css={css`
                    grid-area: feat;
                `}>
                {sidebar}
            </div>

            {isDesktopOrLaptop ? (
                <FadeBox
                    height="200px"
                    css={css`
                        grid-area: sign;
                        font-size: ${t.font.size[2]};
                    `}>
                    <SignUpBox />
                </FadeBox>
            ) : (
                <SignUpBox
                    css={css`
                        grid-area: sign;
                        font-size: ${t.font.size[2]};
                    `}
                />
            )}

            <div
                css={css`
                    grid-area: cont;
                    ${mixin.defaultEndsPadding}
                `}>
                {children}
            </div>

            <Footer
                css={css`
                    grid-area: foot;
                `}
            />
        </GridLayout>
    )
}

export default CenteredLayout

const GridLayout = styled.div`
    display: grid;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "head head head head head head head"
        ". sign . cont . . ."
        ". sign . feat . . ."
        "foot foot foot foot foot foot foot";
    grid-template-rows: max-content auto max-content max-content;
    grid-template-columns: minmax(0vw, ${t.sitewide.sideWidth}) 156px ${t.space[7]} 1fr ${t.space[7]} 156px minmax(
            0vw,
            ${t.sitewide.sideWidth}
        );
    grid-column-gap: 0px;

    ${mixin.tabletandphonestyle} {
        grid-template-columns: 10vw 1fr 10vw;
        grid-template-rows: max-content max-content max-content max-content max-content;
        grid-template-areas:
            "head head head"
            ". cont ."
            ". feat ."
            ". sign ."
            "foot foot foot";
        grid-row-gap: 0;
    }
    ${mixin.phonestyle} {
        grid-template-columns: 5vw 1fr 5vw;
    }
`

/*


        <MediaQuery minWidth={t.breakpoint.large}>
            <FadeBox
                height="200px"
                css={css`
                    grid-area: sign;
                    font-size: ${t.font.size[2]};
                `}>
                <SignUpBox />
            </FadeBox>
        </MediaQuery>

        <MediaQuery maxWidth={t.breakpoint.large}>
            <SignUpBox
                css={css`
                    grid-area: sign;
                    font-size: ${t.font.size[2]};
                `}
            />
        </MediaQuery>


        */

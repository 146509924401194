import React from "react"
import t from "theme"
import { Fab, Action } from "react-tiny-fab"
import "react-tiny-fab/dist/styles.css"
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share"

const FloatingMenu = ({ linkUrl, ...props }) => {
    const pageUrl = "https://disruptionresearch.firebaseapp.com" + linkUrl
    return (
        <Fab
            mainButtonStyles={mainButtonStyles}
            icon={<i class="icon-plus" />}
            event="click"
            position={{ bottom: t.space[1], right: t.space[1] }}
            alwaysShowTitle>
            <Action text="Share on Twitter" style={actionButtonStyles}>
                <TwitterShareButton url={pageUrl}>
                    <i className="icon-twitter" />
                </TwitterShareButton>
            </Action>
            <Action text="Share on Facebook" style={actionButtonStyles}>
                <FacebookShareButton url={pageUrl}>
                    <i className="icon-facebook2" />
                </FacebookShareButton>
            </Action>
            <Action text="Share on LinkedIn" style={actionButtonStyles}>
                <LinkedinShareButton url={pageUrl}>
                    <i className="icon-linkedin" />
                </LinkedinShareButton>
            </Action>
        </Fab>
    )
}

export default FloatingMenu

const mainButtonStyles = {
    backgroundColor: t.color.light,
    color: "white",
}

const actionButtonStyles = {
    backgroundColor: t.color.primary,
    color: "white",
}
